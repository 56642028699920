<template>
  <div class="userList">
    <!-- 头部 -->
    <div class="top">
      <div class="title">
        <div class="title-text">用户管理</div>
        <div class="title-description">
          截止到{{
            $moment(today).format('YYYY-MM-DD')
          }}的最新数据。当前页面仅支持查看1000条数据，如需查看更多，可选择导出。
        </div>
      </div>

      <div class="search">
        <a-form :form="form">
          <a-form-item>
            <a-input
              placeholder="请输入用户ID、姓名或账号"
              v-decorator="['key']"
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select
              v-decorator="['sourceId']"
              allow-clear
              placeholder="选择用户注册渠道"
            >
              <a-select-option
                v-for="item in channelList"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-decorator="['time']"></a-range-picker>
          </a-form-item>
        </a-form>
        <div class="search-button">
          <a-button type="primary" style="margin-right: 10px" @click="search"
            >查询</a-button
          >
          <a-button @click="reSetForm">重置</a-button>
        </div>
      </div>
    </div>
    <!-- 分割线 -->
    <a-divider></a-divider>
    <!-- 用户列表 -->
    <div class="search-result">
      <div>查询结果：共 {{ searchResult }} 条</div>
      <div>
        排序：<a-select v-model="sortMethod" @select="sortChange"
          ><a-select-option :value="0">日期从近到远</a-select-option
          ><a-select-option :value="1">日期从远到近</a-select-option></a-select
        >
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      id="table"
      :columns="columns"
      :data-source="userList"
      bordered
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :pagination="pagination"
      @change="tablePageChange"
    >
      <!-- :scroll="{ x: 600 }" -->
      <!-- 用户ID列 -->
      <span slot="id" slot-scope="text, record">
        <span class="userId" @click="showUserDetail(record)">{{ text }}</span>
      </span>

      <!-- mobile -->
      <!-- <span slot="phoneNumber" slot-scope="text">{{ text ? text : '--' }}</span> -->
      <span slot="phoneNumber" slot-scope="text, record">{{
        record.accountInfo[0].account
      }}</span>
      <!-- <span slot="phoneNumber" slot-scope="text, record">
        <span v-if="record.accountInfo && record.accountInfo.length > 0">{{
          filterAccount(record.accountInfo, 'mobile')
        }}</span>
      </span> -->
      <!-- <span slot="phoneNumber" slot-scope="text, record">{{
        getAccountInfo(record.id)
      }}</span> -->

      <!-- wechat -->
      <span slot="wechatAccount" slot-scope="text">{{
        text ? '已绑定' : '未绑定'
      }}</span>

      <!-- username -->
      <span slot="name" slot-scope="text">{{ text ? text : '--' }}</span>

      <!-- mail -->
      <span slot="email" slot-scope="text">{{ text ? text : '未绑定' }}</span>

      <!-- 状态列 -->
      <span slot="status" slot-scope="text">
        <span v-if="text == '1'" style="color: #28c28e">●正常</span>
        <span v-else-if="text == '2'" style="color: #ff4453">●已禁用</span>
        <span v-else-if="text == '3'" style="color: #b9babe">●已注销</span>
        <span v-else-if="text == '0'" style="color: #ffbe25">●未激活</span>
        <!-- <span v-else style="color: #ff4453">●停用</span> -->
      </span>

      <!-- 渠道 -->
      <span slot="sourceId" slot-scope="text">
        <span v-if="text">{{ channelName(text) }}</span>
        <span v-else>--</span>
      </span>

      <!-- 注册时间 -->
      <span slot="createdAt" slot-scope="text">
        {{ text ? $moment(text).format('YYYY-MM-DD HH:mm:ss') : '--' }}
      </span>
      <!-- <span slot="createdAt" slot-scope="text">{{
        text ? text.replace('T', ' ').replace('Z', ' ') : '--'
      }}</span> -->
      <!-- 操作列 -->
      <span slot="action" slot-scope="text, record">
        <!-- <a v-if="record.status == '1'" @click="stop(record)">停用</a>
        <a v-else @click="recover(record)">恢复</a>
        <a-divider type="vertical" />
        <a @click="modify(record)">修改账号</a>
        <a-divider type="vertical" />
        <a @click="reSet(record)">重置密码</a> -->
        <a @click="showUserDetail(record)">详情</a>
      </span>
    </a-table>
    <!-- 用户详情弹窗 -->
    <user-detail
      v-if="isShowUserDetail"
      :visible="isShowUserDetail"
      :user="user"
      @close="isShowUserDetail = false"
      @refresh="refresh"
    />
    <!-- 修改账号弹窗 -->
    <modify-account
      v-if="isShowModify"
      :visible="isShowModify"
      :user="user"
      @closeModify="isShowModify = false"
      @refresh="refresh"
    />
    <!-- 重置密码弹窗 -->
    <re-set-password
      :visible="isShowReset"
      :userId="userId"
      @closeReset="isShowReset = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import userDetail from '@/views/user/userModal/userDetail.vue'
import modifyAccount from '@/views/user/userModal/modifyAccount.vue'
import reSetPassword from '@/views/user/userModal/reSetPassword.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import { getTableCount } from '@/utils/autoGetTableHeightAndCount'

const columns = [
  {
    title: '用户ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'center',
    width: 200,
    fixed: 'left',
  },
  {
    title: '姓名',
    dataIndex: 'nickname',
    key: 'nickname',
    scopedSlots: { customRender: 'nickname' },
    // width: 150,
    align: 'left',
  },
  // {
  //   title: 'mobile',
  //   dataIndex: 'phoneNumber',
  //   key: 'phoneNumber',
  //   scopedSlots: { customRender: 'phoneNumber' },
  //   width: 150,
  //   align: 'center',
  // },
  // {
  //   title: 'wechat',
  //   dataIndex: 'wechatAccount',
  //   key: 'wechatAccount',
  //   scopedSlots: { customRender: 'wechatAccount' },
  //   width: 150,
  //   align: 'center',
  // },
  // {
  //   title: 'username',
  //   dataIndex: 'name',
  //   key: 'name',
  //   scopedSlots: { customRender: 'name' },
  //   width: 150,
  //   align: 'center',
  // },
  // {
  //   title: 'mail',
  //   dataIndex: 'email',
  //   key: 'email',
  //   scopedSlots: { customRender: 'email' },
  //   width: 200,
  //   align: 'left',
  // },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
    width: 150,
  },
  {
    title: '注册渠道',
    dataIndex: 'sourceId',
    key: 'sourceId',
    scopedSlots: { customRender: 'sourceId' },
    align: 'center',
    width: 150,
  },
  {
    title: '注册时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
    align: 'center',
    width: 200,
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    // width: '230px',
    width: '100px',
    fixed: 'right',
  },
]

const userList = []

export default {
  inject: ['reload'],
  name: 'UserList',
  components: {
    userDetail,
    modifyAccount,
    reSetPassword,
  },
  data() {
    return {
      today: new Date(),
      form: this.$form.createForm(this),
      searchResult: 0,
      columns,
      userList,
      channelList: [],
      selectedRowKeys: [],
      pagination: {
        // defaultCurrent: 1, // 默认当前页数
        // defaultPageSize: 10, // 默认当前页显示数据的大小
        current: 1,
        pageSize: 0,
        total: 0,
        // showSizeChanger: true,
        showQuickJumper: true,
        // pageSizeOptions: ['15', '20', '30'],
      },
      isShowUserDetail: false,
      isShowModify: false,
      user: {} /* 当前选中的用户 */,
      userId: '' /* 当前选中的用户ID */,
      isShowReset: false,
      other: {
        name: '',
        register: '',
        time: [],
      },
      sortMethod: 0,
      // pageSize: 0 /* 根据页面表格大小显示表格页面条数 */,
    }
  },
  async created() {
    // if (!this.$store.state.user.isUsePrevCondition) {
    //   this.getChannel()
    //   this.tablePageChange({ pageSize: 10, current: 1 })
    // }
    // await this.init()
    // console.log(this.userList)
  },
  mounted() {
    // if (this.$store.state.user.isUsePrevCondition) {
    //   /* 使用之前条件查询 */
    //   this.form.setFieldsValue({
    //     name: this.searchCondition_x.name || '',
    //     channel: this.searchCondition_x.channel || '',
    //     time:
    //       this.searchCondition_x.time.length == 0
    //         ? ''
    //         : this.searchCondition_x.time,
    //   })
    //   this.sortMethod = this.sortMethod_x || this.sortMethod
    //   this.pagination.current = this.current_x || 1
    //   this.pagination.pageSize = this.pageSize_x || 10
    //   this.getChannel()
    //   this.tablePageChange({
    //     pageSize: this.pagination.pageSize,
    //     current: this.pagination.current,
    //   })
    // }
    // this.$store.commit('user/usePrevCondition', false)
  },
  beforeDestroy() {
    /* 保存查询条件 */
    // this.saveSearch()
  },
  computed: {
    ...mapState('user', [
      'pageSize_x',
      'current_x',
      'sortMethod_x',
      'searchCondition_x',
      'isUsePrevCondition',
    ]),
    channelName() {
      return function (value) {
        let tag = this.channelList.find((item) => item.id == value)
        if (tag) {
          return tag.name
        } else {
          return '--'
        }
      }
    },
    /* 获取对应类型账号信息 */
    getAccountInfo() {
      return async function (value) {
        let data
        data = await this.getUserAccount(value)
        // .then((res) => {
        //   console.log(res)
        //   data = res
        // })
        return data || '--'
      }
    },
    filterAccount() {
      return function (value, type) {
        // console.log(value, type)
        let typeArr = value.map((item) => item.type)
        let account
        if (typeArr.includes(type)) {
          account = value.filter((item) => item.type == type)[0].account
        } else {
          account = '--'
        }
        // switch (type) {
        //   case 'mobile':
        //     account =  value.filter((item) => item.type == 'mobile')[0].account
        //     break
        //     case 'basic':
        //     account = value.filter(item => item)

        // }
        return account
      }
    },
  },
  methods: {
    // filterAccount(value, type) {
    //   // return function (value, type) {
    //   console.log(value, type)
    //   let typeArr = value.map((item) => item.type)
    //   let account
    //   if (typeArr.includes(type)) {
    //     account = value.filter((item) => item.type == type)[0].account
    //   } else {
    //     account = '--'
    //   }
    //   // switch (type) {
    //   //   case 'mobile':
    //   //     account =  value.filter((item) => item.type == 'mobile')[0].account
    //   //     break
    //   //     case 'basic':
    //   //     account = value.filter(item => item)

    //   // }
    //   return account
    //   // }
    // },
    /* 获取所有可能的注册渠道列表 */
    getChannel() {
      this.$api._getUserChannel().then((res) => {
        // console.log(res, '渠道')
        if (res.code == 0) {
          this.channelList = res.data
        }
      })
    },

    /* 查询 */
    search() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.getUserList(1, this.pagination.pageSize).then((res) => {
            // this.pagination.current = 1
            // this.getUserList().then((res) => {
            // this.userList = res.records
            // this.pagination.total = Number(res.total)
            this.pagination.current = 1
            // this.pagination.pageSize = 15
          })
        }
      })
    },

    /* 表格单选框变化 */
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    /* 重置表单 */
    reSetForm() {
      this.form.resetFields()
      this.tablePageChange({ pageSize: this.pagination.pageSize, current: 1 })
    },

    /* 停用 */
    stop(value) {
      let _this = this
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: '',
              theme: 'filled',
            },
          }),
        title: (h) => (
          <div style="display:flex;align-items: center">
            <a-icon
              type="exclamation-circle"
              theme="filled"
              style="color: #FF4453;font-size: 24px;margin-right: 15px"
            ></a-icon>
            <span>停用</span>
          </div>
        ),

        content: (h) => (
          <div>
            <span style="color: #FF4453">
              停用后该用户所有操作将被影响使用，
            </span>
            <span>你还要继续操作吗</span>
          </div>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.$api.blockUpUser(value.id).then((res) => {
            // console.log(res)
            _this.$message.success('已停用！')
            _this.$store.commit('user/usePrevCondition', true)

            _this.refresh()
          })
        },
        onCancel() {},
      })
    },
    /* 恢复 */
    recover(value) {
      let _this = this
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: 'exclamation-circle',
              theme: 'filled',
            },
          }),
        title: '恢复',
        content: (h) => (
          <div>
            <span style="color: #FF4453">恢复后该用户可以继续操作，</span>
            <span>你还要继续操作吗</span>
          </div>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.$api.recoverUser(value.id).then((res) => {
            // console.log(res)
            _this.$message.success('已恢复！')
            _this.$store.commit('user/usePrevCondition', true)

            _this.refresh()
          })
        },
        onCancel() {},
      })
    },
    /* 修改账号 */
    modify(record) {
      // console.log(record)
      this.user = record
      this.isShowModify = true
    },
    /* 重置密码 */
    reSet(record) {
      this.userId = record.id
      this.isShowReset = true
    },
    /* 展示用户详情 */
    showUserDetail(record) {
      this.user = record
      this.isShowUserDetail = true
    },

    async init() {
      this.getChannel()
      await this.getUserList().then((res) => {})
    },

    refresh() {
      this.isShowUserDetail = false
      this.isShowReset = false
      this.isShowModify = false
      this.init()
    },

    /* 分页获取用户列表 */
    async getUserList(page, size) {
      let values = this.form.getFieldsValue()
      values = this.cleanEmpty(values)
      let other = {
        // order: Boolean(this.sortMethod),
        key: values.key || '',
        sourceId: values.sourceId || '',
        createdAtAfter:
          values.time && values.time.length > 0
            ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        createdAtBefore:
          values.time && values.time.length > 0
            ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        page: page || this.pagination.current,
        size: size || this.pagination.pageSize,
      }
      // console.log(other, '参数')
      other = this.cleanEmpty(other)
      return new Promise((resolve, reject) => {
        this.$api
          .getAllUsersList(other)
          .then(async (res) => {
            // console.log(res, '新用户列表')
            if (res.code == 0) {
              this.userList = res.data.content
              this.pagination.total = Number(res.data.totalElements)
              this.searchResult = res.data.totalElements
              /* 获取用户账号类型列表 */
              // this.userList.forEach(async (item, index, arr) => {
              //   // arr.accountInfo = await this.getUserAccount(item.id)
              //   item.accountInfo = await this.getUserAccount(item.id)
              //   // .then(
              //   //   (data) => {
              //   //     item.accountInfo = data
              //   //   }
              //   // )
              // })

              // console.log(this.userList)
              resolve()
            }
          })
          .catch(() => {
            reject()
          })
      })
    },

    /* 获取用户账号列表 */
    getUserAccount(userId) {
      return new Promise((resolve, reject) => {
        this.$api.getUserAccountList(userId).then((res) => {
          if (res.code == 0) {
            // console.log(res, '账号列表')
            let typeArr = res.data.map((item) => item.type)
            resolve(res.data)
          } else {
            reject()
          }
        })
      })
    },

    /* 分页设置改变 */
    tablePageChange(pagination) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.getUserList().then((res) => {
        // this.userList = res.records
        // this.pagination.total = Number(res.total)
      })
    },

    /* 去空 */
    cleanEmpty(object) {
      return Object.keys(object)
        .filter((key) => {
          return (
            object[key] !== undefined &&
            object[key] !== '' &&
            object[key] !== null
          )
        })
        .reduce((prev, curr) => {
          return { ...prev, [curr]: object[curr] }
        }, {})
    },
    /* 列表排序改变 */
    sortChange(value) {
      // console.log(value)
      this.sortMethod = value
      this.search()
    },
  },
  async mounted() {
    // let tableHeight = document.getElementById('table')
    // let height = tableHeight.offsetHeight
    // console.log(tableHeight, height)

    let pageSize = getTableCount('table', 30, 118)
    this.pagination.pageSize = Math.floor(pageSize)
    // console.log(pageSize)

    await this.init()
    // console.log(this.userList)
  },
}
</script>

<style lang="less" scoped>
// @import '@/assets/CSS/global.less';
.userList {
  background-color: #fff;
  height: calc(100vh - 120px);
  // margin-top: 5px;
  padding: 15px;
  border-radius: 3px;
  // overflow: auto;
}

.top {
  .title {
    // height: 100px;
    margin-bottom: 15px;
    .title-text {
      margin-left: 10px;
      margin-bottom: 15px;
      position: relative;
      font-family: '.PingFang SC S0pxibold';
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      color: #151515;
      /* 伪类 */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        width: 4px;
        height: 20px;
        border-radius: 3px;
        background: #1890ff;
      }
    }
    .title-description {
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      color: #999;
    }
  }

  /deep/ .search {
    // width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    .search-button {
      position: absolute;
      right: 0;
      button {
        width: 100px;
      }
    }
    .ant-form {
      display: flex;
      align-items: center;
    }
    .ant-form-item {
      width: 280px;
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}

/deep/ .search-result {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #4e4e4e;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  div {
    margin-right: 30px;
    .ant-select-selection {
      border: none;
      width: 130px;
    }
  }
}

.userId {
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
}

.account {
  display: flex;
  justify-content: space-between;
}

.touch {
  cursor: pointer;
}

/deep/ .ant-table-column-title {
  font-weight: bold;
  height: 42px !important;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 0px 16px 0px 16px;
  height: 30px !important;
}

/deep/ .ant-table-wrapper {
  height: calc(100% - 200px);
}

// /deep/ .ant-table-align-center,
// .ant-table-align-left {
//   height: 42px !important;
//   padding: 0;
//   font-family: 'PingFang SC Bold';
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 22px;
//   text-align: center;
//   color: #333;
// }
</style>
