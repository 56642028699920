<template>
  <a-modal
    :visible="visible"
    width="540px"
    @ok="handleOk"
    @cancel="handleCancel"
    ok-text="强制退出"
    cancel-text="取消"
    okType="danger"
  >
    <div slot="title" class="title">
      <img
        src="~@/assets/img/redTitle.png"
        style="margin-right: 10px"
      />确定强制退出"{{ companyOne.organizationName }}"
    </div>
    <div class="warning">
      注意：若该用户是企业/组织的超级管理员，强制退出即表示解散该组织。请确认后，在执行操作
    </div>
    <a-form :form="form">
      <a-form-item label="企业/组织名称">
        <a-input
          placeholder="请填写"
          v-decorator="[
            'company',
            { rules: [{ required: true, validator: nameRule }] },
          ]"
        >
          <span slot="suffix">{{ inputLength }}/30</span>
        </a-input>
      </a-form-item>
      <div class="note">输入企业/组织名称，表示您确认强制退出企业/组织</div>
    </a-form>
  </a-modal>
</template>

<script>
import { validate } from 'json-schema'

export default {
  name: 'Exit',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
    companyOne: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: (form, changeValue) => {
          // console.log(changeValue)
          let formItemName = Object.keys(changeValue)[0]
          /* 判断哪个字段发生改变 */
          if (formItemName === 'company') {
            let value = changeValue.company
            this.inputLength = value.length
            if (value.length > 30) {
              /* 超出字数限制时，不在显示 */
              this.$nextTick(() => {
                this.form.setFieldsValue({
                  ['company']: value.slice(0, 30),
                })
              })
              this.inputLength = 30
            }
          } else {
            // console.log(formItemName)
          }
        },
      }),
      inputLength: 0,
    }
  },
  methods: {
    nameRule(rule, value, callback) {
      if (!value) {
        return Promise.reject('请输入企业组织名称')
      } else if (value !== this.companyOne.rentname) {
        return Promise.reject('请输入正确的企业/组织名称')
      } else {
        return Promise.resolve()
      }
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          /* 强制退出企业/组织 */
          this.$api
            .logoutCompany(this.userId)
            .then((res) => {
              if (res.code === 0) {
                this.$message.success('成功退出')
                this.reload()
              }
            })
            .catch((res) => {
              console.log(res)
              this.$message.warning('请使用超级管理员登录操作')
            })
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.$emit('closeExit')
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
}
.warning {
  width: 460px;
  height: 45px;
  font-family: 'PingFangSC-Regular ';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #888;
}

.note {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: left;
  color: #ccc;
}
/deep/ .ant-modal-body {
  padding-left: 60px;
}

.ant-form-item {
  margin-bottom: 0;
}
</style>
