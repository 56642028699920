/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-10-31 10:17:14
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-10-31 10:24:22
 * @FilePath: \cz-operation\src\utils\autoGetTableHieghtAndCount.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function getTableCount(documentIdName, tableRowHeight, otherHeight) {
  let tableHeight = document.getElementById(documentIdName).offsetHeight
  let tableContentHeight = tableHeight - otherHeight
  let pageSize = tableContentHeight / tableRowHeight
  return pageSize
}
