<template>
  <a-modal
    title="重置密码"
    :visible="visible"
    width="540px"
    @ok="handleOk"
    @cancel="handleCancel"
    ok-text="确认修改"
    cancel-text="取消"
  >
    <a-form :form="form">
      <a-form-item
        label="新的登录密码"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="至少6位数，包含数字、英文大小写"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '请输入新的密码' }] },
          ]"
        ></a-input>
      </a-form-item>
      <div class="default">
        <a-checkbox @change="checkChange"> 默认密码：123456 </a-checkbox>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  inject: ['reload'],
  name: 'ReSetPassword',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    handleCancel() {
      this.$emit('closeReset')
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$set(values, 'userId', this.userId)
          console.log(values)
          /* 调用修改密码接口 */
          let _data = {
            newPassword: values.password,
            userId: values.userId,
          }
          this.$api.reSetPassword(_data).then((res) => {
            console.log(res)
            if (res.code == 0) {
              this.$message.success('修改密码成功')
              // this.$store.commit('user/usePrevCondition', true)
              // this.reload()
              this.$emit('refresh')
            } else {
              this.$message.error('修改失败，稍后重试')
            }
          })
        }
      })
    },
    checkChange(value) {
      // console.log(value)
      if (value.target.checked === true) {
        this.form.setFieldsValue({ password: '123456' })
      } else {
        this.form.setFieldsValue({ password: '' })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.default {
  margin-left: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #bfbfbf;
}

.ant-form-item {
  margin-bottom: 14px;
}
</style>
