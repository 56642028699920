<template>
  <a-modal
    :visible="visible"
    title="修改账号"
    width="540px"
    @ok="handleOk"
    @cancel="handleCancel"
    ok-text="确认修改"
    cancel-text="取消"
  >
    <a-form :form="form">
      <a-form-item
        label="当前登陆账号"
        :labelCol="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <div>{{ user.nickname }}</div>
      </a-form-item>
      <a-form-item
        label="新手机号码"
        :labelCol="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="请输入新手机号码"
          v-decorator="[
            'mobile',
            {
              rules: [
                { required: true, validator: phoneRules, trigger: 'blur' },
              ],
            },
          ]"
        >
          <span
            @click="getCaptcha"
            slot="suffix"
            class="code-button"
            :style="{
              pointerEvents: !state.smsSendBtn ? 'auto' : 'none',
              color: !state.smsSendBtn ? '' : '#C4C4C4',
            }"
          >
            {{ state.smsSendBtn ? `${state.time}s` : '获取验证码' }}
          </span>
        </a-input>
      </a-form-item>
      <a-form-item
        label="验证码"
        :labelCol="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-input
          placeholder="请填写"
          v-decorator="[
            'code',
            { rules: [{ required: true, message: '请输入验证码' }] },
          ]"
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
/* 校验手机正则表达式 */
const regExp =
  /^((\+|00)86)?(((123|13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}))$/

export default {
  inject: ['reload'],
  name: 'ModifyAccount',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      state: {
        smsSendBtn: false,
        time: 60,
      },
      timer: null /* 定时器 */,
      regExp,
    }
  },
  created() {
    console.log(this.user)
  },
  methods: {
    /* 手机号码校验规则 */
    phoneRules(rule, value, callback) {
      if (!value) {
        return Promise.reject('请输入新的手机号码')
      } else {
        if (!regExp.test(value)) {
          return Promise.reject('请输入正确的手机号码')
        } else {
          return Promise.resolve()
        }
      }
    },

    /* 验证码校验 */
    codeRules(rule, value, callback) {},
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          /* 发送请求，确认用户输入的验证码是否正确 */
          // this.$api
          //   .verifyCode({
          //     code: values.code,
          //     phonenums: values.mobile,
          //     type: 'login',
          //   })

          let _data = {
            userId: this.user.id,
            code: values.code,
            phoneNumber: values.mobile,
          }
          this.$api.modifyAccount(_data).then((res) => {
            console.log(res)
            if (res.code == 0) {
              this.$message.success('修改成功！')
              // this.$store.commit('user/usePrevCondition', true)

              // this.reload()
              this.$emit('refresh')
            } else {
              this.$message.error(res.message)
            }
          })
          // this.$api
          //   .codeVerify({
          //     code: values.code,
          //     recipient: values.mobile,
          //     type: 'changePhone',
          //     // type: 'bindPhone',
          //   })
          //   .then((res) => {
          //     if (res.code == 0) {
          //       console.log(res)
          //       // this.$message.success('验证码正确！')
          //       /* 验证码正确，可以发送修改账号请求 */
          //       // let data = {
          //       //   nickName: values.mobile,
          //       //   status: this.user.status,
          //       //   userId: this.user.userId,
          //       //   userType: this.user.userType,
          //       //   mobile: values.mobile,
          //       // }

          //     } else {
          //       this.$message.error('验证码错误！')
          //     }
          //   })
        }
      })
    },
    handleCancel() {
      this.$emit('closeModify')
    },
    /* 获取验证码 */
    getCaptcha() {
      this.form.validateFields(['mobile'], (err, values) => {
        if (!err) {
          this.state.smsSendBtn = true
          /* 倒计时60s */
          this.timer = setInterval(() => {
            this.state.time -= 1
          }, 1000)

          /* 发送获取验证码请求 */
          // this.$api
          //   .msgRegisterSend({
          //     phonenums: values.mobile,
          //     signname: '易伙科技',
          //     type: 'login',
          //   })
          //   .then((res) => {
          //     if (res.code === 0) {
          //       this.$message.success('验证码已发送！')
          //     }
          //   })
          //   .catch(() => {
          //     window.clearInterval(this.timer)
          //     this.state.time = 60
          //     this.state.smsSendBtn = false
          //   })

          let str = {
            recipient: values.mobile,
            channelType: ['TENCENT_SMS'],
            mailSubject: '',
            type: 'changePhone',
            scenarioKey: 'CALLZONE_VERIFICATION_CODE',
            variableParam: { signature: 'CALLZONE', operation: '登陆' },
          }

          this.$api
            .newMsgRegisterSend(str)
            .then((res) => {
              console.log(res)
              if (res.code == 0) {
                this.$message.success('验证码已发送！')
              } else {
                this.$message.warning(res.message)
                window.clearInterval(this.timer)
                this.state.time = 60
                this.state.smsSendBtn = false
              }
            })
            .catch(() => {
              window.clearInterval(this.timer)
              this.state.time = 60
              this.state.smsSendBtn = false
            })
        }
      })
    },
  },
  watch: {
    state: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.time === 0) {
          console.log(newValue.time)
          this.state.smsSendBtn = false
          window.clearInterval(this.timer)
          this.timer = null
          this.state.time = 60
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.code-button {
  border: none;
  color: #1890ff;
  cursor: pointer;
}

.ant-form-item {
  margin-bottom: 16px;
}
</style>
