<template>
  <a-modal
    :visible="visible"
    @cancel="handleCancel"
    width="1000px"
    :footer="null"
    :dialog-style="{ top: '30px' }"
  >
    <div slot="title" class="title">用户详情</div>
    <!-- 头部用户基本信息 -->
    <div class="header">
      <div class="header-userName">
        <div class="name">
          <img v-if="user.picture" :src="user.picture" alt="" />
          <img v-else src="~@/assets/img/company.png" alt="" />
          {{ user.nickname || '--' }}
        </div>
        <div>
          <!-- <a-button @click="modify">修改账号</a-button> -->
          <a-button @click="reSet">重置密码</a-button>
          <a-button
            v-if="user.status == '1'"
            @click="stop(user)"
            type="danger"
            style="margin-left: 10px; width: 100px"
            >禁用</a-button
          >
          <a-button
            v-else-if="user.status == '2'"
            @click="recover(user)"
            type="primary"
            style="margin-left: 10px; width: 100px"
            >启用</a-button
          >
          <!-- 修改账号弹窗 -->
          <modify-account
            v-if="isShowModify"
            :visible="isShowModify"
            :user="userInfo"
            @closeModify="isShowModify = false"
            @refresh="refresh"
          />
          <!-- 重置密码弹窗 -->
          <re-set-password
            :visible="isShowReset"
            :userId="userInfo.id"
            @closeReset="isShowReset = false"
            @refresh="refresh"
          />
        </div>
      </div>
      <div class="userInfo">
        <div>
          <span>用户ID:</span><span>{{ user.id }}</span>
        </div>
        <div>
          <span>状态:</span>
          <span v-if="user.status == '1'" style="color: #28c28e">●正常</span>
          <span v-else style="color: #ff4453">●停用</span>
          <!-- <span style="color: #28c28e">●正常</span> -->
        </div>
        <div>
          <span>注册渠道:</span
          ><span v-if="user.sourceId">{{ user.sourceId }}</span
          ><span v-else>--</span>
        </div>
        <div>
          <span>注册时间:</span
          ><span>{{
            user.createdAt
              ? $moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')
              : '--'
          }}</span>
        </div>

        <div>
          <span>最近活跃日期:</span
          ><span>{{
            user.updatedAt
              ? $moment(user.updatedAt).format('YYYY-MM-DD HH:mm:ss')
              : '--'
          }}</span>
        </div>
        <div></div>
      </div>
    </div>

    <!-- tab页，用户详细信息 -->
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab="个人信息">
        <div class="user">
          <div>
            <span>性别:</span>
            <span v-if="user.gender">
              {{ user.gender }}
              <!-- <span v-if="user.gender == 1">男</span><span v-else>女</span> -->
            </span>
            <span v-else>未填写</span>
          </div>
          <!-- <div>
            <span>手机号码:</span><span>{{ user.phoneNumber }}</span>
          </div>
          <div>
            <span>邮箱:</span><span v-if="user.email">{{ user.email }}</span
            ><span v-else>未填写</span>
          </div> -->
          <div>
            <span>证件:</span
            ><span v-if="user.certificateType">{{
              creditType(user.certificateType)
            }}</span
            ><span v-else>--</span>
          </div>
          <div>
            <span>证件号码:</span
            ><span v-if="user.certificateNumber">{{
              user.certificateNumber
            }}</span>
            <span v-else>--</span>
          </div>
          <div>
            <span>生日:</span
            ><span v-if="user.birthdate">{{
              $moment(user.birthdate).format('YYYY-MM-DD')
            }}</span
            ><span v-else>未填写</span>
          </div>
          <div>
            <span>微信:</span><span v-if="user.wx">{{ user.wx }}</span
            ><span v-else>未填写</span>
          </div>
          <div>
            <span>QQ:</span><span v-if="user.qq">{{ user.qq }}</span
            ><span v-else>未填写</span>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="账号信息">
        <a-table
          :columns="columns2"
          :data-source="accountType"
          bordered
          :rowKey="(record) => record.type"
        >
          <span slot="type" slot-scope="text">{{ typeName[text] }}</span>
          <span slot="account" slot-scope="text">{{ text || '--' }}</span>

          <span slot="operation" slot-scope="text, record">
            <a
              v-if="record.type == 'wx' && record.account"
              @click="unbindWechatOrMail(record)"
              >解绑微信</a
            >
            <a v-if="record.type == 'mobile' && record.account" @click="modify"
              >修改账号</a
            >
            <!-- <a
              v-else-if="record.type == 'email' && record.account"
              @click="unbindWechatOrMail(record)"
              >解绑邮箱</a
            > -->
            <a v-else>--</a>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="企业/组织信息" force-render>
        <a-table
          :columns="columns"
          :data-source="companyData"
          bordered
          :rowKey="(record) => record.id"
        >
          <!-- <span slot="action" slot-scope="text, record">
            <a @click="exit(record)">强制退出</a>
          </span> -->
          <template slot="joinedAt" slot-scope="text, record">
            {{ text ? $moment(text).format('YYYY-MM-DD HH:mm:ss') : '--' }}
          </template>
          <template slot="action" slot-scope="text, record">
            <a @click="quitTenant(record)">强制退出</a>
          </template>
        </a-table>
        <exit
          :visible="isShowExit"
          :companyOne="companyOne"
          :userId="userInfo.userId"
          @closeExit="isShowExit = false"
        />
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import exit from '@/views/user/userModal/exit.vue'
import modifyAccount from '@/views/user/userModal/modifyAccount.vue'
import reSetPassword from '@/views/user/userModal/reSetPassword.vue'

const columns = [
  {
    title: 'TenantID',
    key: 'organizationId',
    dataIndex: 'organizationId',
    scopedSlots: { customRender: 'organizationId' },
    align: 'center',
  },
  {
    title: '企业/组织',
    key: 'organizationName',
    dataIndex: 'organizationName',
    scopedSlots: { customRender: 'organizationName' },
    align: 'center',
  },
  {
    title: '加入时间',
    key: 'joinedAt',
    dataIndex: 'joinedAt',
    scopedSlots: { customRender: 'joinedAt' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

const columns2 = [
  {
    title: '类型',
    key: 'type',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
    align: 'center',
  },
  {
    title: '账号',
    key: 'account',
    dataIndex: 'account',
    scopedSlots: { customRender: 'account' },
    align: 'center',
    width: '33.3%',
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
    width: '33.3%',
  },
]

const accountList = []
export default {
  inject: ['reload'],
  name: 'UserDetail',
  components: {
    exit,
    modifyAccount,
    reSetPassword,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      env: process.env.VUE_APP_BASE_URL,
      userInfo: {
        name: '用户名称全程',
        id: 2749372,
        accountId: 27498723476892,
        registerTime: '2019-02-01',
        state: 1,
        channel: 'MyDAMA小程序',
        lastActive: '2022-08-01',
      },
      columns,
      columns2,
      accountList,
      companyData: [],
      isShowExit: false,
      isShowModify: false,
      isShowReset: false,
      userInfo: {},
      lastTime: '',
      companyOne: {},
      accountType: [
        {
          type: 'mobile',
          account: '',
        },
        {
          type: 'wx',
          account: '',
        },
        {
          type: 'basic',
          account: '',
        },
        {
          type: 'email',
          account: '',
        },
      ],
      typeName: {
        mobile: '手机账号',
        wx: '微信绑定',
        basic: '用户名',
        email: '邮箱账号',
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    creditType() {
      return function (value) {
        switch (value) {
          case '0':
            return '身份证'
          case '1':
            return '护照'
          case '2':
            return '军官证'
          case '3':
            return '其他'
        }
      }
    },
  },
  methods: {
    /* 获取用户最近获取时间 */
    getLastTime() {
      this.$api.lastTime(this.user.id).then((res) => {
        // console.log(res)

        this.lastTime = res.timestamp
      })
    },

    /* 解绑微信按钮 */
    unbindWechatOrMail(record) {
      // console.log(record)
      let _this = this
      let data = {
        userId: this.user.id,
        // type: record.type == 'wechat' ? 'wx' : 'email',
        type: record.type,
      }
      // return
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: '',
              theme: 'filled',
            },
          }),
        title: (h) => (
          <div style="display:flex;align-items: center">
            <a-icon
              type="exclamation-circle"
              theme="filled"
              style="color: #FF4453;font-size: 24px;margin-right: 15px"
            ></a-icon>
            <span>解除绑定</span>
          </div>
        ),

        content: (h) => <div>确定解绑？</div>,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this
            .unbindAccount(data)
            .then((res) => {
              // console.log(res)
              _this.$message.success('解绑成功！')
              _this.$emit('refresh')
            })
            .catch((err) => {
              _this.$message.error('解绑失败' + err)
            })
        },
        onCancel() {},
      })
    },

    /* 解绑（微信、邮箱） */
    unbindAccount(data) {
      return new Promise((resolve, reject) => {
        this.$api.unbind(data).then((res) => {
          if (res.code == 0) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    /* 强制退出组织 */
    quitTenant(record) {
      // console.log(record)
      let _this = this
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: '',
              theme: 'filled',
            },
          }),
        title: (h) => (
          <div style="display:flex;align-items: center">
            <a-icon
              type="exclamation-circle"
              theme="filled"
              style="color: #FF4453;font-size: 24px;margin-right: 15px"
            ></a-icon>
            <span>强制退出</span>
          </div>
        ),

        content: (h) => <div>确定退出租户？</div>,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.$api
            .quitTenant(record.organizationId, _this.user.id)
            .then((res) => {
              // console.log(res)
              _this.$message.success('已退出！')
              _this.$emit('refresh')
            })
        },
        onCancel() {},
      })
    },

    refresh() {
      // this.init()
      // this.isShowModify = false
      // this.isShowReset = false
      this.$emit('refresh')
    },
    async init() {
      // console.log(this.user)
      this.userInfo = this.user
      // this.getLastTime()
      this.getUserRenter()
      /* 初始化账号类型 */
      await this.getUserAccount()

      // console.log(this.accountType)
    },

    /* 获取用户所加入的租户列表信息 */
    getUserRenter() {
      return new Promise((resolve, reject) => {
        this.$api.getTenantListByUser(this.user.id).then((res) => {
          if (res.code == 0) {
            // console.log(res)
            this.companyData = res.data
            resolve()
          } else {
            reject()
          }
        })
        // this.$api.getRenter(this.user.tid).then((res) => {
        //   console.log(res)
        //   if (res.code === 0) {
        //     if (Object.keys(res.data).length > 0) {
        //       this.companyData = [res.data]
        //     } else {
        //       this.companyData = []
        //     }
        //     resolve()
        //   } else {
        //     reject()
        //   }
        // })
      })
    },

    /* 获取用户账号列表 */
    getUserAccount() {
      return new Promise((resolve, reject) => {
        this.$api.getUserAccountList(this.user.id).then((res) => {
          if (res.code == 0) {
            // console.log(res, '账号列表')
            let typeArr = res.data.map((item) => item.type)

            this.accountType.forEach((item, index, arr) => {
              if (item.type === 'mobile' && typeArr.includes('mobile'))
                // arr[index].account = this.user.phoneNumber
                arr[index] = res.data.filter((item) => item.type == 'mobile')[0]
              // if (item.type === 'wechat') arr[index].account = this.user.wx
              if (item.type === 'wx' && typeArr.includes('wx'))
                arr[index] = res.data.filter((item) => item.type == 'wx')[0]
              if (item.type === 'basic' && typeArr.includes('basic')) {
                // arr[index] = res.data.filter((item) => item.type == 'wechat')[0]
                arr[index] = res.data.filter((item) => item.type == 'basic')[0]
              }
              // if (item.type === 'email') arr[index].account = this.user.email
              if (item.type === 'email' && typeArr.includes('email'))
                arr[index] = res.data.filter((item) => item.type == 'email')[0]
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },

    handleCancel() {
      this.$emit('close')
    },
    /* tab页改变 */
    tabChange(key) {
      // console.log(key)
    },
    /* 强制退出 */
    exit(record) {
      // console.log(record)
      this.companyOne = record
      this.isShowExit = true
    },
    /* 修改账号 */
    modify(record) {
      // console.log(record)

      this.isShowModify = true
    },
    /* 重置密码 */
    reSet(record) {
      this.isShowReset = true
    },
    /* 禁用 */
    stop(value) {
      let _this = this
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: '',
              theme: 'filled',
            },
          }),
        title: (h) => (
          <div style="display:flex;align-items: center">
            <a-icon
              type="exclamation-circle"
              theme="filled"
              style="color: #FF4453;font-size: 24px;margin-right: 15px"
            ></a-icon>
            <span>停用</span>
          </div>
        ),

        content: (h) => (
          <div>
            <span style="color: #FF4453">
              禁用后该用户所有操作将被影响使用，
            </span>
            <span>你还要继续操作吗</span>
          </div>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.$api.blockUpUser(_this.user.id).then((res) => {
            // console.log(res)
            _this.$message.success('已停用！')
            // _this.reload()
            // _this.refresh()
            _this.$emit('refresh')
          })
        },
        onCancel() {},
      })
    },
    /* 恢复 */
    recover(value) {
      let _this = this
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: 'exclamation-circle',
              theme: 'filled',
            },
          }),
        title: '恢复',
        content: (h) => (
          <div>
            <span style="color: #FF4453">恢复后该用户可以继续操作，</span>
            <span>你还要继续操作吗</span>
          </div>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.$api.recoverUser(_this.user.id).then((res) => {
            // console.log(res)
            _this.$message.success('已恢复！')
            // _this.reload()
            // _this.refresh()
            _this.$emit('refresh')
          })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  margin-left: 10px;
  margin-bottom: 15px;
  position: relative;
  font-family: '.PingFang SC S0pxibold';
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  color: #151515;
  /* 伪类 */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    border-radius: 3px;
    background: #1890ff;
  }
}

.header {
  width: 100%;
  margin-bottom: 20px;
  .header-userName {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .name {
      font-family: 'PingFang SC Bold';
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      color: #333;
      img {
        margin-right: 10px;
        width: 34px;
        height: 34px;
      }
    }
  }
  .userInfo {
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent;
    border: 1px solid #e7e7e7;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0 20px 0;
    div {
      width: 33%;
      padding-left: 30px;
      font-family: '苹方-简 常规体';
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      color: #333;
      line-height: 30px;
      span:first-child {
        margin-right: 10px;
      }
    }
  }
}

.user {
  div {
    height: 20px;
    font-family: '苹方-简 常规体';
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
    span:first-child {
      margin-right: 10px;
    }
  }
}
</style>
